import React from 'react';
import { useMutation, useApolloClient } from '@apollo/react-hooks';
import first from 'lodash/first';
import { ME_QUERY } from 'hooks/meQuery';
import RefreshTokenService from 'services/RefreshTokenService';

const useAuth = (mutation) => {
  const [authMutation, { loading }] = useMutation(mutation);
  const client = useApolloClient();
  const auth = React.useCallback(
    async (variables) => {
      try {
        const res = await authMutation({ variables });
        const resultData = first(Object.values(res.data));

        if (resultData.refreshToken) {
          RefreshTokenService.setRefreshToken(resultData.refreshToken);
        }

        client.writeQuery({
          query: ME_QUERY,
          data: { me: resultData.user },
        });
        return resultData;
      } catch (error) {
        const e = error.graphQLErrors ? new Error(error.graphQLErrors.map((err) => err.message)) : error;
        e.raw = error;
        throw e;
      }
    },
    [authMutation, client],
  );

  return [auth, loading];
};

export default useAuth;
