import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { CRow, CCol, CCard, CCardHeader, CCardBody, CDataTable, CButton } from '@coreui/react';
import * as yup from 'yup';
import { Formik, Form, Field } from 'formik';
import TextInput from 'components/inputs/TextInput';
import { Link } from 'react-router-dom';
import routePaths from 'router/route-paths';
import Button from 'components/common/Button';
import s from './LoanConsultantsTable.module.scss';

const initialValue = {
  email: '',
  firstName: '',
  lastName: '',
};

const schema = yup.object().shape({
  email: yup
    .string()
    .email()
    .required(<FormattedMessage id="errors.fill_field" />),
  firstName: yup.string().required(<FormattedMessage id="errors.fill_field" />),
  lastName: yup.string().required(<FormattedMessage id="errors.fill_field" />),
});

const LoanConsultantsTable = ({ onAddLoanConsultant, onDeleteLoanConsultant, fields, loanConsultants }) => {
  const { formatMessage: t } = useIntl();
  return (
    <CRow>
      <CCol xl={8} className="mr-auto ml-auto">
        <CCard>
          <CCardHeader className={s.header}>
            <div>{t({ id: 'loan_consultants.title' })}</div>
            <Link to={routePaths.reports}>
              <Button color="primary">{t({ id: 'reports.generate_report' })}</Button>
            </Link>
          </CCardHeader>

          <Formik initialValues={initialValue} validationSchema={schema} onSubmit={onAddLoanConsultant}>
            {({ isSubmitting }) => (
              <>
                <Form>
                  <div className={s.card}>
                    <Field type="email" name="email" component={TextInput} required label={t({ id: 'auth.email' })} />
                    <Field
                      type="text"
                      name="firstName"
                      component={TextInput}
                      required
                      label={t({ id: 'user.first_name' })}
                    />
                    <Field
                      type="text"
                      name="lastName"
                      component={TextInput}
                      required
                      label={t({ id: 'user.last_name' })}
                    />
                    <div style={{ textAlign: 'right' }}>
                      <CButton disabled={isSubmitting} color="primary" type="submit">
                        {t({ id: 'loan_consultants.add_loan_consultant_btn' })}
                      </CButton>
                    </div>
                  </div>
                </Form>
              </>
            )}
          </Formik>

          <CCardBody>
            <CDataTable
              items={loanConsultants}
              fields={fields}
              itemsPerPage={20}
              addTableClasses={s.loanConsultantsTable}
              scopedSlots={{
                delete: ({ name, email }) => (
                  <td>
                    <CButton
                      block
                      color="danger"
                      variant="outline"
                      onClick={() => onDeleteLoanConsultant({ email, name })}
                    >
                      {t({ id: 'loan_consultants.delete' })}
                    </CButton>
                  </td>
                ),
              }}
            />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};

export default LoanConsultantsTable;
