import routePaths, { routePatterns } from 'router/route-paths';
import settings from 'config/settings';
import { MEINBAU } from 'constants/constants';

export default [
  {
    _tag: 'CSidebarNavItem',
    name: 'page_titles.projects',
    to: routePatterns.projects.stringify({ page: 1 }),
    icon: 'cil-list-rich',
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'page_titles.messages',
    to: routePatterns.messages.stringify({ page: 1 }),
    icon: 'cil-envelope-closed',
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'page_titles.loan',
    to: routePaths.loan,
    icon: 'cil-euro',
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'page_titles.express_loan_settings',
    to: routePaths.expressLoanSettings,
    icon: 'cil-settings',
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'page_titles.admins',
    to: routePaths.admins,
    icon: 'cil-people',
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'page_titles.loan_consultants',
    to: routePaths.loanConsultants,
    icon: 'cil-people',
    hide: settings.appName === MEINBAU,
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'page_titles.customer_data_analytics',
    to: routePatterns.customerDataAnalytics.stringify({ page: 1 }),
    icon: 'cib-google-analytics',
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'page_titles.co2_report',
    to: routePatterns.co2SavingsReport.stringify({ page: 1 }),
    icon: 'cil-chart-line',
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'page_titles.financing_requests',
    to: routePatterns.financingRequests.stringify({ page: 1 }),
    icon: 'cil-money',
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'page_titles.hypo_noe_api_requests_report',
    to: routePatterns.hypoNoeApiRequestsReport.stringify({ page: 1 }),
    icon: 'cil-arrow-circle-right',
    hide: settings.appName === MEINBAU,
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'page_titles.reports',
    to: routePaths.reports,
    icon: 'cil-list-rich',
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'page_titles.notifications',
    to: routePaths.notifications,
    icon: 'cil-bell',
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'page_titles.analytics',
    to: '',
    icon: 'cil-external-link',
    onClick: () => window.open(settings.googleAnalytics, '_blank'),
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'page_titles.logout',
    to: routePaths.logout,
    icon: 'cil-exit-to-app',
    className: 'mt-auto',
  },
];
