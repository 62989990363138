import { CCard, CCardBody, CCardHeader } from '@coreui/react';
import ReportForm from 'components/projects/ReportForm';
import settings from 'config/settings';
import React from 'react';
import { useIntl } from 'react-intl';

const reports = [
  {
    url: settings.projectsReportUrl,
    header: 'reports.type.projects',
  },
  {
    url: settings.registeredUsersReportUrl,
    header: 'reports.type.registered_users',
  },
  {
    url: settings.loanConsultantsUrl,
    header: 'reports.type.loan_consultants',
  },
  {
    url: settings.customerDataAnalyticsUrl,
    header: 'reports.type.customer_data_analytics',
  },
];

const Reports = () => {
  const { formatMessage: t } = useIntl();

  return (
    <>
      {reports.map(({ url, header, generateReportBtnLabel }) => (
        <CCard key={header}>
          <CCardHeader>
            <h4>{t({ id: header })}</h4>
          </CCardHeader>
          <CCardBody style={{ paddingLeft: 0, paddingRight: 0 }}>
            <ReportForm {...{ generateReportBtnLabel, url }} />
          </CCardBody>
        </CCard>
      ))}
    </>
  );
};

export default Reports;
