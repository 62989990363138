/* eslint-disable no-unused-expressions */
import { format } from 'date-fns';
import { de } from 'date-fns/locale';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';

const DEFAULT_LOCALE = 'de-DE';

export function __(id, values) {
  return <FormattedMessage {...{ id, values }} />;
}

export const wait = (timeout = 3000) =>
  new Promise((resolve) => {
    setTimeout(resolve, timeout);
  });

export const isSmallScreen = () => {
  if (window.innerWidth <= 748) {
    return true;
  }
  return false;
};

export const spliceLabelOnMobile = (value) => {
  if (!isSmallScreen()) return value;
  if (typeof value !== 'string') return value;
  if (value.length > 40) {
    return `${value.slice(0, 37)}...`;
  }
  return value;
};

function getLocale() {
  return localStorage.getItem('language') || DEFAULT_LOCALE;
}

export const value2LocaleValue = (value) => {
  // const config = { de: 'de-DE', en: 'en-GB' };
  const locale = getLocale();
  return Intl.NumberFormat(locale).format(value);
};

export const formatCurrency = (() => {
  const locale = getLocale();
  const formatter = new Intl.NumberFormat(locale, { style: 'currency', currency: 'EUR' });
  return (number) => {
    const parts = formatter.formatToParts(number);
    const strArray = [];
    // filter out the decimal part
    parts.forEach((part) => {
      if (['decimal', 'fraction'].includes(part.type)) return;
      strArray.push(part.value);
    });
    return strArray.join('');
  };
})();

export const formatDate = (date, dateFormat = 'do MMMM yyyy HH:mm') => {
  return format(new Date(date), dateFormat, { locale: de });
};

export const changeLanguage = () => {
  let countClicks = window?.localStorage?.getItem('countClicksOnFooter');

  if (Number(countClicks) === 5) {
    countClicks = 0;

    const language = window?.localStorage?.getItem('language');
    window?.localStorage?.setItem('language', language === 'en' ? 'de' : 'en');

    document.location.reload();
  }

  if (!countClicks) window?.localStorage?.setItem('countClicksOnFooter', 1);
  else window?.localStorage?.setItem('countClicksOnFooter', Number(countClicks) + 1);
};

export const formatGraphqlErrors = (error) => {
  const e = error.graphQLErrors ? new Error(error.graphQLErrors.map((err) => err.message)) : error;
  e.raw = error || '';
  return e;
};

export const grabErrorMessage = (error) => formatGraphqlErrors(error).message;

export const toastErrorMessage = (error) => {
  const e = formatGraphqlErrors(error);
  toast.error(e.message);
};
