import gql from 'graphql-tag';

export const CURRENT_USER_FRAGMENT = gql`
  fragment currentUser on User {
    _id
    emails {
      address
      verified
    }
    profile {
      firstName
      lastName
    }
    roles
    is2FAEnabled
  }
`;

export const ME_QUERY = gql`
  query me {
    me {
      ...currentUser
    }
  }
  ${CURRENT_USER_FRAGMENT}
`;
