import { useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { toastErrorMessage } from 'utils/helpers';

const RETRY_API_REQUEST = gql`
  mutation retryHypoNoeApiRequest($requestId: ID!) {
    retryHypoNoeApiRequest(requestId: $requestId) {
      _id
      request_params {
        project_name {
          parameter
          value
        }
        project_type {
          parameter
          value
        }
      }
      response {
        status
        error
      }
      user {
        profile {
          firstName
          lastName
        }
        emails {
          address
        }
      }
      created_at
    }
  }
`;

const useRetryApiRequest = () => {
  const [retryApiRequestMutation] = useMutation(RETRY_API_REQUEST);

  const handleRetryRequest = useCallback(
    async (requestId) => {
      try {
        await retryApiRequestMutation({ variables: { requestId } });
      } catch (error) {
        toastErrorMessage(error);
      }
    },
    [retryApiRequestMutation],
  );

  return handleRetryRequest;
};

export default useRetryApiRequest;
