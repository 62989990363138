import { useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { toastErrorMessage } from 'utils/helpers';

const ADJUST_EXPRESS_LOAN_SETTING_MUTATION = gql`
  mutation adjustExpressLoanSettings($formData: ExpressLoanSettingsInput!) {
    adjustExpressLoanSettings(formData: $formData) {
      total_account_fees
      execution_fee
    }
  }
`;

const useAdjustExpressLoanSettings = () => {
  const [adjustExpressLoanSettings] = useMutation(ADJUST_EXPRESS_LOAN_SETTING_MUTATION);

  const handleSubmit = useCallback(
    async (formData) => {
      try {
        await adjustExpressLoanSettings({ variables: { formData } });
      } catch (error) {
        toastErrorMessage(error);
      }
    },
    [adjustExpressLoanSettings],
  );
  return handleSubmit;
};

export default useAdjustExpressLoanSettings;
