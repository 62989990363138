import get from 'lodash/get';
import { useQuery, useApolloClient } from '@apollo/react-hooks';
import { ME_QUERY } from './meQuery';
import { afterLogout } from './auth/useLogout';

export default function useUser() {
  const { loading, error, data, refetch } = useQuery(ME_QUERY);
  const client = useApolloClient();

  const roles = get(data, 'me.roles', []);

  if (data?.me && !roles.includes('admin')) {
    afterLogout(client);
  }

  return [data?.me, loading, error, refetch];
}
