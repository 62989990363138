import CIcon from '@coreui/icons-react';
import { CBadge, CButton, CDataTable } from '@coreui/react';
import Pagination from 'components/common/Pagination';
import { COUNT_TABLE_RECORDS } from 'constants/constants';
import { get, inRange } from 'lodash';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { formatDate } from 'utils/helpers';
import { cilReload, cilInfo } from '@coreui/icons';
import useRetryApiRequest from 'hooks/hypoNoeApiRequest/userRetryHypoNoeApiRequest';
import s from './HypoNoeApiRequestsTable.module.scss';

const APIRequestsTable = ({ requests, showModal, changePage, currentPage, total }) => {
  const { formatMessage: t } = useIntl();
  const onRetryRequest = useRetryApiRequest();

  const fields = useMemo(
    () => [
      { key: 'user_info', label: t({ id: 'hypo_noe_api_request.user_info' }) },
      { key: 'project_name', label: t({ id: 'hypo_noe_api_request.project_name' }) },
      { key: 'project_type', label: t({ id: 'hypo_noe_api_request.project_type' }) },
      { key: 'request_details', label: t({ id: 'hypo_noe_api_request.details' }) },
      { key: 'created_at', label: t({ id: 'hypo_noe_api_request.created_at' }) },
      { key: 'status', label: t({ id: 'hypo_noe_api_request.status' }) },
      { key: 'error', label: t({ id: 'hypo_noe_api_request.error' }) },
      { key: 'retry_request', label: t({ id: 'hypo_noe_api_request.retry_request' }) },
    ],
    [t],
  );

  const items = useMemo(
    () =>
      requests.map(({ _id, request_params, response, user, created_at }) => {
        const { project_type, project_name } = request_params;
        const { status, error } = response;
        const email = get(user, 'emails[0].address', '');
        const userInfo = user
          ? `${user.profile.firstName} ${user.profile.lastName} \n ${email}`
          : t({ id: 'user.user_not_found' });

        return {
          email,
          error,
          status,
          user_info: userInfo,
          created_at: formatDate(created_at),
          requestId: _id,
          project_name: project_name.value,
          project_type: project_type.value,
        };
      }),
    [requests, t],
  );

  return (
    <>
      <CDataTable
        items={items}
        fields={fields}
        size="sm"
        itemsPerPage={COUNT_TABLE_RECORDS}
        addTableClasses={s.table}
        scopedSlots={{
          retry_request: ({ status, requestId }) => {
            if (status === 200 || status === 202) return <td />;
            return (
              <td className="text-center">
                <CButton className={s.iconButton} onClick={() => onRetryRequest(requestId)}>
                  <CIcon content={cilReload} className="mt-0" />
                </CButton>
              </td>
            );
          },
          status: ({ status }) => (
            <td>
              <CBadge
                shape="pill"
                color={`${inRange(status, 200, 300) ? 'success' : 'danger'}`}
                style={{ fontSize: '14px' }}
              >
                {`${status}`}
              </CBadge>
            </td>
          ),
          request_details: ({ requestId }) => (
            <td>
              <CButton onClick={() => showModal({ requestId })} className={s.iconButton}>
                <CIcon content={cilInfo} />
              </CButton>
            </td>
          ),
        }}
      />
      <Pagination {...{ changePage, currentPage, total }} />
    </>
  );
};

export default APIRequestsTable;
