import { useMemo } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import get from 'lodash/get';
import { COUNT_TABLE_RECORDS } from 'constants/constants';

export const GET_HYPO_NOE_API_REQUESTS = gql`
  query hypoNoeApiRequests($limit: Int!, $offset: Int!, $filter: String) {
    hypoNoeApiRequests(limit: $limit, offset: $offset, filter: $filter) {
      requests {
        _id
        request_params {
          project_name {
            parameter
            value
          }
          project_type {
            parameter
            value
          }
        }
        response {
          status
          error
        }
        user {
          profile {
            firstName
            lastName
          }
          emails {
            address
          }
        }
        created_at
      }
      count
    }
  }
`;

const useGetHypoNoeApiRequests = ({ currentPage, filter }) => {
  const { data, loading } = useQuery(GET_HYPO_NOE_API_REQUESTS, {
    variables: { filter, offset: COUNT_TABLE_RECORDS * (currentPage - 1), limit: COUNT_TABLE_RECORDS },
  });

  const requests = useMemo(() => get(data, 'hypoNoeApiRequests.requests', []), [data]);
  const total = get(data, 'hypoNoeApiRequests.count', 0);

  return { requests, total, loading };
};

export default useGetHypoNoeApiRequests;
