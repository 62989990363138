import gql from 'graphql-tag';
import get from 'lodash/get';
import { useQuery } from '@apollo/react-hooks';

export const EXPRESS_LOAN_SETTINGS_QUERY = gql`
  query expressLoanSettings {
    expressLoanSettings {
      total_account_fees
      execution_fee
    }
  }
`;

const useExpressLoanSettings = () => {
  const { data, loading, error } = useQuery(EXPRESS_LOAN_SETTINGS_QUERY);
  const expressLoanSettings = get(data, 'expressLoanSettings', null);
  return { expressLoanSettings, error, loading };
};

export default useExpressLoanSettings;
