import React, { useState, useCallback } from 'react';
import ReactModal from 'react-modal';
import CIcon from '@coreui/icons-react';
import { cilX } from '@coreui/icons';
import styles from './Modal.module.scss';

ReactModal.setAppElement(document.getElementById('root'));

export const useModal = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [modalProps, setModalProps] = useState({});
  const showModal = useCallback((data = {}) => {
    setModalProps(data);
    setIsVisible(true);
  }, []);

  const hideModal = useCallback(() => {
    setIsVisible(false);
    setModalProps({});
  }, []);

  return { showModal, hideModal, modalProps, isVisible };
};

const customStyles = {
  content: {
    // width: '50%',
    // height: '50%',
    // top: '50%',
    // left: '50%',
    // right: 'auto',
    // bottom: 'auto',
  },
};

const Modal = ({ children, isVisible, close, header, modalStyle = {} }) => (
  <ReactModal
    isOpen={isVisible}
    onRequestClose={close}
    style={{ ...customStyles, ...modalStyle }}
    bodyOpenClassName={styles.bodyOpen}
    className={{
      base: styles.modal,
      afterOpen: styles.modalAfterOpen,
      beforeClose: styles.modalBeforeClose,
    }}
    overlayClassName={{
      base: styles.overlay,
      afterOpen: styles.overlayAfterOpen,
      beforeClose: styles.overlayBeforeClose,
    }}
    closeTimeoutMS={300}
  >
    <div className={styles.modalHeader}>
      {header ? <h4 className="my-3">{header}</h4> : null}
      <button type="button" className={styles.modalCloseButton} onClick={close}>
        <CIcon content={cilX} />
      </button>
    </div>
    <div className={styles.modalBody}>{children}</div>
  </ReactModal>
);

export default Modal;
