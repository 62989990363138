import { useMemo } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import get from 'lodash/get';
import { COUNT_TABLE_RECORDS } from 'constants/constants';

const GET_FINANCING_REQUESTS = gql`
  query financingRequests($limit: Int!, $offset: Int!, $filter: String) {
    financingRequests(limit: $limit, offset: $offset, filter: $filter) {
      requests {
        projectName
        user {
          firstName
          lastName
          email
        }
        express_loan_id
        message
        created_at
        archived
      }
      count
    }
  }
`;

const useGetFinancingRequests = ({ currentPage, filter }) => {
  const { data, loading } = useQuery(GET_FINANCING_REQUESTS, {
    variables: { filter, offset: COUNT_TABLE_RECORDS * (currentPage - 1), limit: COUNT_TABLE_RECORDS },
  });

  const financingRequests = useMemo(() => get(data, 'financingRequests.requests', []), [data]);
  const total = get(data, 'financingRequests.count', 0);

  return { financingRequests, total, loading };
};

export default useGetFinancingRequests;
