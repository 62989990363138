import Button from 'components/common/Button';
import ScrollToError from 'components/common/ScrollToError';
import { Field, Form, Formik } from 'formik';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import NumberInput from 'components/inputs/NumberInput';
import * as yup from 'yup';
import { grabErrorMessage, __ } from 'utils/helpers';
import { CAlert, CCard, CCardBody, CCardHeader, CCol, CRow } from '@coreui/react';
import useAdjustExpressLoanSettings from 'hooks/expressLoan/useAdjustExporessLoanSettings';
import useExpressLoanSettings from 'hooks/expressLoan/useExpressLoanSettings';
import Loading from 'components/common/Loading';
import { toast } from 'react-toastify';

const validationSchema = yup.object().shape({
  total_account_fees: yup
    .number()
    .required(__({ id: 'errors.fill_field' }))
    .nullable(__({ id: 'errors.fill_field' })),
  execution_fee: yup
    .number()
    .required(__({ id: 'errors.fill_field' }))
    .nullable(__({ id: 'errors.fill_field' })),
});

const ExpressLoanSettings = ({ expressLoanSettings }) => {
  const { formatMessage: t } = useIntl();
  const adjustExpressLoanSettings = useAdjustExpressLoanSettings();

  const onSubmit = useCallback(
    async (values, helpers) => {
      await adjustExpressLoanSettings(values);
      toast.success(t({ id: 'expressLoan.changes_applied' }));
      helpers.setSubmitting(false);
    },
    [adjustExpressLoanSettings, t],
  );

  const initialValues = {
    total_account_fees: expressLoanSettings?.total_account_fees || 0,
    execution_fee: expressLoanSettings?.execution_fee || 0,
  };

  return (
    <CRow>
      <CCol xs="12" lg="12">
        <CCard>
          <CCardHeader>{t({ id: 'page_titles.financing_requests' })}</CCardHeader>
          <CCardBody>
            <Formik {...{ initialValues, onSubmit, validationSchema }}>
              {({ isSubmitting }) => (
                <Form>
                  <ScrollToError />
                  <Field
                    name="total_account_fees"
                    component={NumberInput}
                    required
                    label={t({ id: 'expressLoan.total_account_fees' })}
                  />
                  <Field
                    name="execution_fee"
                    component={NumberInput}
                    required
                    label={t({ id: 'expressLoan.execution_fee' })}
                  />

                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button type="submit" loading={isSubmitting} disabled={isSubmitting}>
                      {t({ id: 'buttons.save' })}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};

export default () => {
  const { expressLoanSettings, loading, error } = useExpressLoanSettings();

  if (loading) return <Loading size={50} />;

  if (error) return <CAlert>{grabErrorMessage(error)}</CAlert>;

  return <ExpressLoanSettings {...{ expressLoanSettings }} />;
};
