import React from 'react';
import get from 'lodash/get';
import { CFormGroup, CLabel, CInput, CInvalidFeedback } from '@coreui/react';

const TextInput = ({ field, form, disabled = false, type = 'text', label }) => {
  const { name, onBlur } = field;
  const { touched, errors, setFieldValue, setFieldTouched } = form;

  const getClassNameForInput = get(touched, name) && get(errors, name) ? 'form-control is-invalid' : 'form-control';

  const handleChange = React.useCallback(
    (e) => {
      setFieldValue(name, e.target.value);
    },
    [name, setFieldValue],
  );

  const handleOnBlur = React.useCallback(() => {
    onBlur();
    setFieldTouched(name, true);
  }, [name, onBlur, setFieldTouched]);

  return (
    <CFormGroup>
      <CLabel htmlFor={name}>{label}</CLabel>
      <CInput
        {...field}
        id={name}
        disabled={disabled}
        type={type}
        className={getClassNameForInput}
        onBlur={handleOnBlur}
        onChange={handleChange}
      />
      {get(touched, name) && get(errors, name) && (
        <CInvalidFeedback className="help-block">{errors[name]}</CInvalidFeedback>
      )}
    </CFormGroup>
  );
};

export default TextInput;
