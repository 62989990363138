import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { routePatterns } from 'router/route-paths';

export default function NotFound() {
  return (
    <>
      <h1>
        <FormattedMessage id="page_not_found.title" />
      </h1>
      <Link to={routePatterns.projects.stringify({ page: 1 })} className="link">
        <FormattedMessage id="app.back" />
      </Link>
    </>
  );
}
