import Button from 'components/common/Button';
import DatePicker from 'components/inputs/DatePicker';
import { endOfDay, startOfMonth } from 'date-fns';
import { FastField, Form, Formik } from 'formik';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';

const ReportForm = ({ url }) => {
  const { formatMessage: t } = useIntl();
  const onSubmit = useCallback(() => {}, []);
  const initialValues = {
    dateRange: [startOfMonth(new Date()), endOfDay(new Date())],
  };

  return (
    <Formik {...{ initialValues, onSubmit }}>
      {({ values }) => (
        <Form>
          <FastField
            name="dateRange"
            label="reports.form.date_range"
            component={DatePicker}
            selectsRange
            isClearable
            startDate={initialValues.dateRange[0]}
            endDate={initialValues.dateRange[1]}
          />

          <a
            href={`${url}${
              values.dateRange[0] && values.dateRange[1]
                ? `?startDate=${values.dateRange[0]}&endDate=${values.dateRange[1]}`
                : ''
            }`}
            onClick={(e) => e.stopPropagation()}
          >
            <Button color="primary">{t({ id: 'reports.export_btn' })}</Button>
          </a>
        </Form>
      )}
    </Formik>
  );
};

export default ReportForm;
