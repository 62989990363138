import { useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation, useApolloClient } from '@apollo/react-hooks';
import RefreshTokenService from 'services/RefreshTokenService';
import { useAppContext } from 'contexts/AppContext';
import { ME_QUERY } from 'hooks/meQuery';

export const afterLogout = (client) => {
  RefreshTokenService.clearRefreshToken();
  client.writeQuery({ query: ME_QUERY, data: { me: null } });
  client.resetStore().catch();
};

export const LOGOUT_MUTATION = gql`
  mutation logout {
    logout {
      success
    }
  }
`;

const useLogout = () => {
  const client = useApolloClient();
  const [logoutMutation] = useMutation(LOGOUT_MUTATION);
  const { toggleSidebar } = useAppContext();

  const logout = useCallback(async () => {
    await logoutMutation();
    afterLogout(client);
    toggleSidebar(false);
  }, [client, logoutMutation, toggleSidebar]);

  return logout;
};

export default useLogout;
