/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { TokenRefreshLink } from 'apollo-link-token-refresh';
import { backendErrors as enBackendErrors } from 'i18n/en';
import { backendErrors as deBackendErrors } from 'i18n/de';
import get from 'lodash/get';
import RefreshTokenService from 'services/RefreshTokenService';
import { afterLogout, LOGOUT_MUTATION } from 'hooks/auth/useLogout';
import settings from '../config/settings';

const link = ApolloLink.from([
  new TokenRefreshLink({
    accessTokenField: 'accessToken',
    isTokenValidOrUndefined: () => {
      return RefreshTokenService.isTokenValidOrUndefined();
    },
    fetchAccessToken: () => {
      return RefreshTokenService.fetchNewAccessToken();
    },
    handleFetch: () => console.log('refreshed a token with apollo TokenRefreshLink'),
    handleResponse: () => (response) => {
      return response;
    },
    handleError: () => {
      window.setTimeout(() => {
        window.setTimeout(async () => {
          try {
            await client.mutate({
              mutation: LOGOUT_MUTATION,
            });
          } catch (e) {
            console.log('Error while logout', e);
          }
          afterLogout(client);
        });
      });
    },
  }),
  setContext((_, { headers }) => {
    return { headers };
  }),
  onError(({ graphQLErrors, response, networkError }) => {
    const errorCode = response?.errors[0]?.extensions?.code;
    if (errorCode === 'FORBIDDEN') {
      window.location.replace(`${window.location.origin}/logout`);
    }
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path, extensions }) => {
        const { code, exception } = extensions;
        const stacktraceItems = get(exception, 'stacktrace', []);
        const stacktrace = stacktraceItems.map((item) => item).join('\n');
        const location = JSON.stringify(locations);
        console.error(
          `[GraphQL error]: Message: ${message}, Code: ${code}, Location: ${location}, Stacktrace ${stacktrace} Path: ${path}`,
        );
      });
    }
    if (networkError) console.log(`[Network error]: ${networkError}`);
  }),
  onError(({ graphQLErrors }) => {
    const locale = window?.localStorage?.getItem('language') || settings.defaultLocale;
    const backendErrorsTranslations = { de: deBackendErrors, en: enBackendErrors };
    if (graphQLErrors) {
      const translateMessage = (error) => {
        const messageKey = `${locale}.${error.message}`;
        const translated = get(backendErrorsTranslations, messageKey, error.message);
        if (translated !== messageKey) {
          error.originalMessage = error.message;
          error.message = translated;
        }
      };
      graphQLErrors.forEach(translateMessage);
    }
  }),
  new HttpLink({
    uri: settings.graphqlServerUrl,
    credentials: 'include',
  }),
]);

const cacheIndexBlacklist = [];

const client = new ApolloClient({
  // request: operation => {
  //   const token = TokenManager.getToken();
  //   console.log(token);
  //   if (token) {
  //     const headers = { Authorization: `Bearer ${token}` };
  //     operation.setContext({ headers });
  //   }
  // },
  link,
  cache: new InMemoryCache({
    freezeResults: true, // new
    dataIdFromObject: ({ _id, __typename }) => {
      if (cacheIndexBlacklist.includes(__typename)) return null;
      return _id ? `${__typename}___${_id}` : null;
    },
  }),
  assumeImmutableResults: true, // new
});

export default client;
