import gql from 'graphql-tag';
import { CURRENT_USER_FRAGMENT } from 'hooks/meQuery';
import useAuth from './useAuth';

const LOGIN_MUTATION = gql`
  mutation loginWithPassword($email: String, $plainPassword: String, $isAdmin: Boolean, $otp: String) {
    loginWithPassword(email: $email, plainPassword: $plainPassword, isAdmin: $isAdmin, otp: $otp) {
      id
      refreshToken
      user {
        ...currentUser
      }
    }
  }
  ${CURRENT_USER_FRAGMENT}
`;

const useLogin = () => {
  const [login] = useAuth(LOGIN_MUTATION);
  return login;
};

export default useLogin;
